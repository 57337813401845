import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
          {/*<header className="App-header">*/}
          {/*  <img src={logo} className="App-logo" alt="logo"/>*/}
          {/*  <p>*/}
          {/*    Edit <code>src/App.js</code> and save to reload.*/}
          {/*  </p>*/}
          {/*  <a*/}
          {/*      className="App-link"*/}
          {/*      href="https://reactjs.org"*/}
          {/*      target="_blank"*/}
          {/*      rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    Learn React*/}
          {/*  </a>*/}
          {/*</header>*/}
          <div className="logo">
              {/* Insert your logo SVG here */}
              <img src={logo} className="logo-svg" alt="mundostra"/>
          </div>

          <div className="title">mundostra</div>

          <div className="subtitle">
              <button className="subtitle-button">ALL-IN-ONE TRAVEL PLATFORM</button>
          </div>

          <div className="confidential">
              COMING SOON
          </div>

          <div className="contact-us">
              INFO@MUNDOSTRA.COM
          </div>
      </div>
  );
}

export default App;
